html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-color-swatch{
  border:none;
}

::-moz-color-swatch{
  border:none;
}

.parentheses-list ol {
  counter-reset: list;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}

.parentheses-list ol > li {
  list-style: none;
  list-style-position: outside !important;
}

.parentheses-list ol > li:before {
  content: "(" counter(list) ") ";
  counter-increment: list;
  
}

.lower-alpha ol {
  counter-reset: list;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 2em;
}
.lower-alpha ol > li {
  list-style: none;
  list-style-position: outside !important;
}
.lower-alpha ol > li:before {
  counter-increment: list;
  content: "(" counter(list, lower-alpha) ") ";
  position: absolute;
}

.lower-roman ol {
  counter-reset: list;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 2em;
}
.lower-roman ol > li {
  list-style: none;
  list-style-position: outside !important;
}

.lower-roman ol > li:before {
  counter-increment: list;
  content:  "(" counter(list, lower-roman) ") ";
  position: absolute;
}

.replace{
  position: relative;
}
.replace:after{
  position: absolute;
  content: '-->';
  background: #fff;
  left: 120px;
  top: 0;
  font-size: 12px;
}

.timeline table thead th {
  position: sticky;
  top: 0;
}

.timeline table {
  border-collapse: separate; /* Don't collapse */
}

.timeline table thead th:first-child {
  position: sticky;
  left: 0;
}
.timeline table tbody th {
  position: sticky;
  left: 0;
}